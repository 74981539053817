import { useEffect } from 'react'
import { Divider, Skeleton, TextField, MenuItem, Button, Box, Typography, Chip, Tooltip, IconButton, InputAdornment, SelectChangeEvent, Select } from '@mui/material'
import Grid from '@mui/material/Grid2'
import ZDELines from './ZDELines'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { format, formatISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { ITimecard, OncallRequest, TimecardLine } from '../interface/ISelectedDate'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { IUser } from '../interface/IUser'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { setAlert } from '../source/slice/AlertSlice'
import axios, { AxiosRequestConfig } from 'axios'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { UpdateStoreHelper } from '../source/StoreHelper'
import ViolationComment from './ViolationComment'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const ZDEHeader = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { instance, accounts } = useMsal()

    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)

    let fromTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => ((line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_START}`) && (line.mserp_oncalltimeandattendancejobid !== 'RB')))[0]?.mserp_startdatetime
    let toTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => ((line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_STOP}`) && (line.mserp_oncalltimeandattendancejobid !== 'RB')))[0]?.mserp_stopdatetime
    let breakfromTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}`)[0]?.mserp_startdatetime
    let breaktoTime: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}`)[0]?.mserp_stopdatetime

    let breaks: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}` && line.delete === false)

    let oncallAllowed: boolean = user.worker.mserp_mbrecordoncallduty
    let oncallValue: string | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_oncalltimeandattendancejobid === 'RB')[0]?.mserp_mboncallapplicationid

    const getBreaksTime = () => {
        var breaksTime: number = 0.0
        breaks?.forEach((breakItem) => {
            //if (breakItem.mserp_stopdatetime && breakItem.mserp_startdatetime) breaksTime += (new Date(breakItem.mserp_stopdatetime).getTime() - new Date(breakItem.mserp_startdatetime).getTime()) / 1000 / 60 / 60
            if (breakItem.mserp_calculatedtimeseconds) breaksTime += breakItem.mserp_calculatedtimeseconds / 60 / 60
        })
        return breaksTime
    }

    /*     var breaksTime: number = 0.0
    breaks?.forEach((breakItem) => {
        console.log(breakItem.mserp_stopdatetime, breakItem.mserp_startdatetime)
        if (breakItem.mserp_stopdatetime && breakItem.mserp_startdatetime) breaksTime += (new Date(breakItem.mserp_stopdatetime).getTime() - new Date(breakItem.mserp_startdatetime).getTime()) / 1000 / 60 / 60
    }) */

    let breakTime: number = 0.0
    if (breakfromTime !== undefined && breaktoTime !== undefined) {
        breakTime = (new Date(breaktoTime).getTime() - new Date(breakfromTime).getTime()) / 1000 / 60 / 60
    }

    let travelTime: number = selectedDate?.header?.mserp_mbquantitytravel ? (isNaN(selectedDate?.header?.mserp_mbquantitytravel) ? 0 : selectedDate?.header?.mserp_mbquantitytravel) : 0

    let workingTime: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingTime = (new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - getBreaksTime()
    }

    let workingtimeAccordingArbZG: number = 0.0
    if (fromTime !== undefined && toTime !== undefined) {
        workingtimeAccordingArbZG = (new Date(toTime).getTime() - new Date(fromTime).getTime()) / 1000 / 60 / 60 - getBreaksTime() - travelTime
    }

    const sumMenge =
        selectedDate?.lines?.reduce((sum, line) => {
            if (!line.mserp_calculatedtimeseconds || line.mserp_timeandattendancejobid === process.env.REACT_APP_JID_BREAK || line.delete === true) return sum
            /* const start = new Date(line.mserp_startdatetime)
            const stop = new Date(line.mserp_stopdatetime)
            const duration = (stop.getTime() - start.getTime()) / (1000 * 60 * 60) */
            const dur = line.mserp_calculatedtimeseconds / 60 / 60
            return sum + dur
        }, 0) || 0

    const remainingTime = workingTime - sumMenge

    const workingTimeDisplay = isNaN(workingTime) ? 0 : workingTime
    const remainingTimeDisplay = isNaN(remainingTime) ? 0 : remainingTime

    const handleDateChange = (jobId: string, fieldName: string) => (event: any) => {
        let newCurrentDate: Date
        var currentDate = selectedDate.header?.mserp_timeprofiledate
        var currentDateTime
        if (!isNaN(event?.getTime()) && currentDate !== undefined && typeof currentDate === 'string') {
            newCurrentDate = new Date(currentDate)
            newCurrentDate.setHours(event.getHours())
            newCurrentDate.setMinutes(event.getMinutes())
            currentDateTime = formatISO(newCurrentDate)

            handleLineChange(jobId, fieldName, currentDateTime)
        }
    }

    /* const handleBreakChange = (jobId: string, fieldName: string, element: any, linenumber: number) => (event: any) => {
        if (breaks) {
            var currentDate = selectedDate.header?.mserp_timeprofiledate
            var currentDateTime
            if (!isNaN(event?.getTime()) && currentDate !== undefined && typeof currentDate === 'string') {
                var newCurrentDate = new Date(currentDate)
                newCurrentDate.setHours(event.getHours())
                newCurrentDate.setMinutes(event.getMinutes())
                currentDateTime = formatISO(newCurrentDate)

                let nobreaks: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid !== `${process.env.REACT_APP_JID_BREAK}`)

                let breaksCopy = [...breaks]
                breaksCopy[linenumber] = {
                    ...breaksCopy[linenumber],
                    [fieldName]: currentDateTime,
                }

                var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
                if (newtimecardlines !== undefined && nobreaks) {
                    let newtimecardlinesCopy = [...nobreaks, ...breaksCopy]

                    var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                    if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                        newselectedDate.lines = newtimecardlinesCopy
                    }
                    dispatch(setSelectedDate(newselectedDate))
                }
            }
        }
    } */

    const handleBreakAmountChange = (fieldName: string, linenumber: number) => (event: any) => {
        var amount = event.target.value
        if (breaks) {
            if (!isNaN(amount)) {
                amount = Number(event.target.value) * 60 * 60
                let nobreaks: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid !== `${process.env.REACT_APP_JID_BREAK}`)

                let breaksCopy = [...breaks]
                breaksCopy[linenumber] = {
                    ...breaksCopy[linenumber],
                    [fieldName]: amount,
                }

                var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
                if (newtimecardlines !== undefined && nobreaks) {
                    let newtimecardlinesCopy = [...nobreaks, ...breaksCopy]

                    var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                    if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                        newselectedDate.lines = newtimecardlinesCopy
                    }
                    dispatch(setSelectedDate(newselectedDate))
                }
            }
        }
    }

    /*     const handleBreakLineChange = (lineno: any, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]
            newtimecardlinesCopy[lineno] = {
                ...newtimecardlinesCopy[lineno],
                [fieldName]: newValue,
            }

            var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
            if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                newselectedDate.lines = newtimecardlinesCopy
            }
            dispatch(setSelectedDate(newselectedDate))
        }
    } */

    const handleLineChange = (jobId: string, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]

            if (selectedDate !== undefined && selectedDate.lines !== undefined) {
                var index = selectedDate?.lines?.findIndex((line: TimecardLine) => line.mserp_timeandattendancejobid === jobId)

                if (jobId === `${process.env.REACT_APP_JID_START}` || jobId === `${process.env.REACT_APP_JID_STOP}`) {
                    newtimecardlinesCopy[index] = {
                        ...newtimecardlinesCopy[index],
                        mserp_startdatetime: newValue,
                        mserp_stopdatetime: newValue,
                    }
                } else {
                    newtimecardlinesCopy[index] = {
                        ...newtimecardlinesCopy[index],
                        [fieldName]: newValue,
                    }
                }

                var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                    newselectedDate.lines = newtimecardlinesCopy
                }
                dispatch(setSelectedDate(newselectedDate))
            }
        }
    }

    const handleBreakLineChange = (lineno: any, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid === `${process.env.REACT_APP_JID_BREAK}` && line.delete === false)
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]
            newtimecardlinesCopy[lineno] = {
                ...newtimecardlinesCopy[lineno],
                [fieldName]: newValue,
            }

            var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
            if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                newselectedDate.lines = newtimecardlinesCopy
            }
            dispatch(setSelectedDate(newselectedDate))
        }
    }

    const handleHeaderNumberChange = (fieldName: string) => (event: any) => {
        var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
        const newValue = event.target.value

        if (newselectedDate !== undefined && newselectedDate.header !== undefined && newValue !== undefined) {
            newselectedDate.header[fieldName] = Number(newValue)
        }
        dispatch(setSelectedDate(newselectedDate))
    }

    const formatDate = () => {
        return i18n.language === 'de' ? (
            format(new Date(selectedDate?.header?.mserp_timeprofiledate ?? new Date().toISOString()), 'EEEE, dd.MM.yyyy', {
                locale: de,
            })
        ) : (
            format(new Date(selectedDate?.header?.mserp_timeprofiledate ?? new Date().toISOString()), 'EEEE, MM/dd/yyyy', {
                locale: enUS,
            })
        )
    }

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.deleteSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.deleteError'),
        type: 'error',
    }

    const deleteTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                    },
                }

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedDate?.header?.mserp_timeprofiledate) UpdateStoreHelper(format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd'), accessToken)
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const handleResetDay = () => {
        const resetSelectedDate: ITimecard = {
            header: {
                ...selectedDate.header,
                mserp_mbquantitylegal: 0,
                mserp_mbquantitytravel: 0,
                mserp_mbelectronictimecardheaderentityid: '',
                mserp_workerpersonnelnumber: '',
                mserp_dataareaid: '',
                mserp_timeprofileid: '',
                mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate || '',
                mserp_istransferred: 200000000,
                isTransferred: false,
                status: 'OPEN',
                mserp_mbviolationcomment: '',
                mserp_mbnoviolationduetotravel: 200000000,
                mserp_mbviolationbreaktime: 200000000,
                mserp_mbviolationdayafter: 200000000,
                mserp_mbviolationdaybefore: 200000000,
                mserp_mbviolationmaximumtime: 200000000,
                mserp_mbviolationsundaywork: 200000000,
            },
            lines: [],
        }

        dispatch(setSelectedDate(resetSelectedDate))
    }

    const addNewBreak = () => {
        var newEmptyLine = {
            mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate,
            mserp_workerpersonnelnumber: selectedDate.header?.mserp_workerpersonnelnumber,
            mserp_dataareaid: user.resource.mserp_resourcecompanyid,
            mserp_mbelectronictimecardlineentityid: '',
            mserp_startdatetime: '',
            mserp_stopdatetime: '',
            mserp_linenumber: 0,
            mserp_projectid: '',
            mserp_projectcategoryid: '',
            mserp_timeandattendancejobid: process.env.REACT_APP_JID_BREAK,
            mserp_indirectactivitycategoryid: 'BREAK', //TODO change constant id
            mserp_calculatedtimeseconds: 0,
            delete: false,
        }

        var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
        if (newselectedDate && newselectedDate.lines) {
            newselectedDate.lines = [...(selectedDate.lines || []), newEmptyLine]
        }
        dispatch(setSelectedDate(newselectedDate))
    }

    const deleteBreak = (jobId: string, fieldName: string, element: any, linenumber: number) => (event: any) => {
        if (breaks) {
            let nobreaks: TimecardLine[] | undefined = selectedDate?.lines?.filter((line: TimecardLine) => line.mserp_timeandattendancejobid !== `${process.env.REACT_APP_JID_BREAK}`)

            let breaksCopy = [...breaks]
            breaksCopy[linenumber] = {
                ...breaksCopy[linenumber],
                delete: true,
            }

            var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
            if (newtimecardlines !== undefined && nobreaks) {
                let newtimecardlinesCopy = [...nobreaks, ...breaksCopy]

                var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                    newselectedDate.lines = newtimecardlinesCopy
                }
                dispatch(setSelectedDate(newselectedDate))
            }
        }
    }

    const handleOncallDropdown = (event: SelectChangeEvent<typeof oncallValue>) => {
        const value = event.target.value
        updateOncallLines(value)
    }

    const updateOncallLines = (newValue: string = '') => {
        const emptyField = (newValue === '') ? true : false
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines

        if (newtimecardlines) {
            let oncallLineIndexArray: number[] = []

            newtimecardlines.forEach((line: TimecardLine, index: number) => {
                if (line.mserp_oncalltimeandattendancejobid === 'RB') {
                    oncallLineIndexArray.push(index)
                }
            })

            let newtimecardlinesCopy = [...newtimecardlines]
            if (oncallLineIndexArray.length > 0) {
                if (!emptyField) {
                    oncallLineIndexArray.forEach((index) => {
                        newtimecardlinesCopy[index] = {
                            ...newtimecardlinesCopy[index],
                            mserp_mboncallapplicationid: newValue,
                            delete: false,
                        }
                    })
                } else {
                    oncallLineIndexArray.forEach((index) => {
                        newtimecardlinesCopy[index] = {
                            ...newtimecardlinesCopy[index],
                            mserp_mboncallapplicationid: '',
                            delete: true,
                        }
                    })
                }

                var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
                if (newselectedDate && newselectedDate.lines) {
                    newselectedDate.lines = newtimecardlinesCopy
                }
                dispatch(setSelectedDate(newselectedDate))
            } else if (!emptyField) {
                addNewOncallLines(newValue)
            }
        }
    }

    const addNewOncallLines = (value: string) => {
        var currentDate = selectedDate.header?.mserp_timeprofiledate
        let newCurrentDate: Date = new Date(currentDate ? currentDate : Date.now())
        newCurrentDate.setHours(0, 0, 0, 0)
        var currentDateTime: string = formatISO(newCurrentDate)

        var newOncallLineClockIn = {
            mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate,
            mserp_workerpersonnelnumber: selectedDate.header?.mserp_workerpersonnelnumber,
            mserp_dataareaid: user.resource.mserp_resourcecompanyid,
            mserp_mbelectronictimecardlineentityid: '',
            mserp_startdatetime: currentDateTime,
            mserp_stopdatetime: currentDateTime,
            mserp_linenumber: 0,
            mserp_projectid: '',
            mserp_projectcategoryid: '',
            mserp_timeandattendancejobid: process.env.REACT_APP_JID_START,
            mserp_indirectactivitycategoryid: '',
            mserp_calculatedtimeseconds: 0,
            mserp_oncalltimeandattendancejobid: 'RB',
            mserp_mboncallapplicationid: value,
            delete: false,
        }
        var newOncallLineClockOut = {
            mserp_timeprofiledate: selectedDate.header?.mserp_timeprofiledate,
            mserp_workerpersonnelnumber: selectedDate.header?.mserp_workerpersonnelnumber,
            mserp_dataareaid: user.resource.mserp_resourcecompanyid,
            mserp_mbelectronictimecardlineentityid: '',
            mserp_startdatetime: currentDateTime,
            mserp_stopdatetime: currentDateTime,
            mserp_linenumber: 0,
            mserp_projectid: '',
            mserp_projectcategoryid: '',
            mserp_timeandattendancejobid: process.env.REACT_APP_JID_STOP,
            mserp_indirectactivitycategoryid: '',
            mserp_calculatedtimeseconds: 0,
            mserp_oncalltimeandattendancejobid: 'RB',
            mserp_mboncallapplicationid: '',
            delete: false,
        }

        var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
        if (newselectedDate && newselectedDate.lines) {
            newselectedDate.lines = [...(selectedDate.lines || []), newOncallLineClockIn, newOncallLineClockOut]
        }
        dispatch(setSelectedDate(newselectedDate))
    }

    const getStatusLabel = (status: string) => {
        switch (status) {
            case 'OPEN':
                return t('calendar.open')
            case 'RELEASED':
                return t('calendar.released')
            case 'SAVED':
                return t('calendar.saved')
            case 'ABSENCE':
                return t('calendar.absence')
            case 'HOLIDAY':
                return t('calendar.holiday')
            case 'WEEKEND':
                return t('calendar.weekend')
            default:
                return ''
        }
    }

    const getStatusStringWithBadge = (status: string) => {
        switch (status) {
            case 'OPEN':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <FiberManualRecordOutlinedIcon color="disabled" sx={{ height: 8 }} />
                        {t('calendar.open')}
                    </Box>
                )
            case 'RELEASED':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon color="success" sx={{ height: 8 }} />
                        {t('calendar.released')}
                    </Box>
                )
            case 'SAVED':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: '#ddf0dd' }} sx={{ height: 8 }} />
                        {t('calendar.saved')}
                    </Box>
                )
            case 'ABSENCE':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: 'rgb(107, 0, 138)' }} sx={{ height: 8 }} />
                        {t('calendar.absence')}
                    </Box>
                )
            case 'HOLIDAY':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon style={{ color: '#ECDEF1' }} sx={{ height: 8 }} />
                        {t('calendar.holiday')}
                    </Box>
                )
            case 'WEEKEND':
                return (
                    <Box component="div" sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        <CircleIcon color="disabled" sx={{ height: 8 }} />
                        {t('calendar.weekend')}
                    </Box>
                )
            default:
                return ''
        }
    }

    const getStatusChip = (status: string) => {
        switch (status) {
            case 'OPEN':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            case 'RELEASED':
                return <Chip label={t('calendar.released')} color="success" variant="filled" />
            case 'SAVED':
                return <Chip label={t('calendar.saved')} color="success" variant="outlined" />
            case 'ABSENCE':
                return <Chip label={t('calendar.absence')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'HOLIDAY':
                return <Chip label={t('calendar.holiday')} color="primary" style={{ color: 'rgb(107, 0, 138)' }} variant="filled" />
            case 'WEEKEND':
                return <Chip label={t('calendar.open')} color="default" variant="outlined" />
            default:
                return ''
        }
    }

    useEffect(() => {}, [selectedDate, user])

    return (
        <>
            <Box display="flex" flexWrap="wrap" alignItems="end" gap="16px" justifyContent="space-between" width="100%">
                {!loadingDate && user ? (
                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                        <Typography variant="h4">
                            {formatDate()} | ({user?.resource?.mserp_resourceid})
                        </Typography>
                        {getStatusChip(selectedDate?.header?.status || '')}
                    </Box>
                ) : (
                    <Typography variant="h4">
                        <Skeleton variant="text" sx={{ width: { xs: 200, md: 300 }, display: 'inline-block' }} />
                    </Typography>
                )}
                <Button variant="outlined" onClick={deleteTimecard} startIcon={<RestartAltIcon />} color="error" disabled={selectedDate?.header?.isTransferred}>
                    {t('zdeheader.resetDay')}
                </Button>
            </Box>
            <Divider sx={{ marginY: '8px' }} />

            <Grid container spacing={1} alignItems="center">
                {/* <Grid item size={{ xs: 4 }}>
                    <span>{t('zdeheader.state')}</span>
                </Grid>
                <Grid item size={{ xs: 8 }}>
                    <span>
                        {!loadingDate ? (
                            <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }}>
                                {getStatusStringWithBadge(selectedDate?.header?.status || '')}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" width="50%" />
                        )}
                    </span>
                </Grid> */}
                <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                    <Typography variant="body1">{t('zdeheader.workingtime')}</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 7, md: 8 }} marginBottom="8px">
                    {!loadingDate ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                            <TimePicker
                                views={['hours', 'minutes']}
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ width: '120px' }}
                                value={fromTime ? new Date(fromTime) : null}
                                onChange={handleDateChange(`${process.env.REACT_APP_JID_START}`, 'mserp_startdatetime')}
                                disabled={selectedDate?.header?.isTransferred}
                            />
                            <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }} px={2}>
                                {t('zdeheader.to')}
                            </Typography>
                            <TimePicker
                                views={['hours', 'minutes']}
                                ampm={false}
                                slotProps={{ textField: { size: 'small' } }}
                                sx={{ width: '120px' }}
                                value={toTime ? new Date(toTime) : null}
                                onChange={handleDateChange(`${process.env.REACT_APP_JID_STOP}`, 'mserp_startdatetime')}
                                disabled={selectedDate?.header?.isTransferred}
                            />
                        </LocalizationProvider>
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                    <Typography>{t('zdeheader.break')}</Typography>
                </Grid>

                <Grid size={{ xs: 12, sm: 7, md: 8 }} marginBottom="8px">
                    {!loadingDate ? (
                        <>
                            {breaks && breaks?.length > 0 ? (
                                breaks?.map((element: TimecardLine, i: number) =>
                                    !element.delete ? (
                                        <Box paddingTop={i !== 0 ? 2 : 0} key={i} display="flex" alignItems="center" gap="6px">
                                            {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                                            <TimePicker
                                                views={['hours', 'minutes']}
                                                ampm={false}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ width: '120px' }}
                                                defaultValue={element.mserp_startdatetime ? new Date(element.mserp_startdatetime) : null}
                                                onChange={handleBreakChange(`${process.env.REACT_APP_JID_BREAK}`, 'mserp_startdatetime', element, i)}
                                                disabled={selectedDate?.header?.isTransferred}
                                            />
                                            <Typography display="inline-block" variant="body1" sx={{ lineHeight: '40px' }} px={2}>
                                                {t('zdeheader.to')}
                                            </Typography>
                                            <TimePicker
                                                views={['hours', 'minutes']}
                                                ampm={false}
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ width: '120px' }}
                                                defaultValue={element.mserp_stopdatetime ? new Date(element.mserp_stopdatetime) : null}
                                                onChange={handleBreakChange(`${process.env.REACT_APP_JID_BREAK}`, 'mserp_stopdatetime', element, i)}
                                                disabled={selectedDate?.header?.isTransferred}
                                            />
                                        </LocalizationProvider>
                                        <TextField
                                            //value={breakTime ? breakTime.toFixed(2) : 0}
                                            value={(
                                                ((element.mserp_stopdatetime ? new Date(element.mserp_stopdatetime).getTime() : new Date().getTime()) -
                                                    (element.mserp_startdatetime ? new Date(element.mserp_startdatetime).getTime() : new Date().getTime())) /
                                                1000 /
                                                60 /
                                                60
                                            ).toFixed(2)}
                                            type="number"
                                            size="small"
                                            disabled
                                            sx={{ width: '120px', marginLeft: 2 }}
                                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        /> */}
                                            <TextField
                                                defaultValue={(element.mserp_calculatedtimeseconds ? element.mserp_calculatedtimeseconds / 60 / 60 : 0).toFixed(2)}
                                                type="number"
                                                size="small"
                                                sx={{ width: '120px' }}
                                                slotProps={{
                                                    htmlInput: { style: { textAlign: 'center' }, min: 0, step: 0.25 },
                                                    input: {
                                                        endAdornment: <InputAdornment position="start">h</InputAdornment>,
                                                    },
                                                }}
                                                //onChange={(e: any) => handleLineChange(`${process.env.REACT_APP_JID_BREAK}`, 'mserp_calculatedtimeseconds', e.target.value * 60 * 60)}
                                                onChange={handleBreakAmountChange('mserp_calculatedtimeseconds', i)}
                                                disabled={selectedDate?.header?.isTransferred}
                                            />
                                            {i === 0 ? (
                                                <Tooltip title={t('zdelines.addbreak')}>
                                                    <IconButton size="small" onClick={addNewBreak} disabled={selectedDate?.header?.isTransferred}>
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={t('zdelines.deletebreak')}>
                                                    <IconButton size="small" onClick={deleteBreak(`${process.env.REACT_APP_JID_BREAK}`, 'delete', element, i)} disabled={selectedDate?.header?.isTransferred}>
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    ) : (
                                        <></>
                                    )
                                )
                            ) : (
                                <Tooltip title={t('zdelines.addbreak')}>
                                    <IconButton size="small" onClick={addNewBreak} disabled={selectedDate?.header?.isTransferred}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                {/* <Grid item size={{ xs: 4 }}>
                    <Typography color="grey">{t('zdeheader.break')} Sum</Typography>
                </Grid>

                <Grid item size={{ xs: 8 }}>
                    <TextField value={getBreaksTime().toFixed(2)} type="number" size="small" disabled sx={{ width: '120px' }} inputProps={{ min: 0, style: { textAlign: 'center' } }} />
                </Grid> */}

                <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                    <Typography>{t('zdeheader.traveltime')}</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 7, md: 8 }} marginBottom="8px">
                    {!loadingDate ? (
                        <TextField
                            value={selectedDate?.header?.mserp_mbquantitytravel ?? 0}
                            onChange={handleHeaderNumberChange('mserp_mbquantitytravel')}
                            type="number"
                            size="small"
                            sx={{ width: '120px' }}
                            slotProps={{
                                htmlInput: { style: { textAlign: 'center' }, min: 0 },
                                input: {
                                    endAdornment: <InputAdornment position="start">h</InputAdornment>,
                                },
                            }}
                            disabled={selectedDate?.header?.isTransferred}
                        />
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                    <Typography>{t('zdeheader.workingtimeAccordingArbZG')}</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 7, md: 8 }} marginBottom="8px">
                    {!loadingDate ? (
                        <TextField
                            type="number"
                            disabled
                            size="small"
                            value={workingtimeAccordingArbZG.toFixed(2)}
                            sx={{ width: '120px' }}
                            slotProps={{
                                htmlInput: { style: { textAlign: 'center' }, min: 0 },
                                input: {
                                    endAdornment: <InputAdornment position="start">h</InputAdornment>,
                                },
                            }}
                        />
                    ) : (
                        <Skeleton variant="text" width="50%" />
                    )}
                </Grid>

                {oncallAllowed && (
                    <>
                        <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                            {/* {(!loadingDate && selectedDate?.oncallRequests) ? (
                                <FormControlLabel control={<Checkbox />} label={<Typography>{t('zdeheader.oncall')}</Typography>} onChange={handleOncallCheckbox()} checked={oncallEnabled} defaultChecked={false} disabled={selectedDate?.header?.isTransferred} />
                            ) : (
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Skeleton variant="rounded" sx={{ height: 16, width: 16 }} />
                                    <Typography>{t('zdeheader.oncall')}</Typography>
                                </Box>
                            )} */}
                            <Typography>{t('zdeheader.oncall')}</Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 7, md: 8 }} marginBottom="8px">
                            {(!loadingDate && selectedDate?.oncallRequests) ? (
                                <Select size="small" sx={{ width: 250 }} value={oncallValue ? oncallValue : ''} onChange={handleOncallDropdown} disabled={selectedDate?.header?.isTransferred}>
                                    <MenuItem key={''} value={''} selected>Auswahl entfernen...</MenuItem>
                                    {selectedDate?.oncallRequests.map((oncall: OncallRequest, i: number) => (
                                        <MenuItem key={oncall.mserp_mbapplicationid + `_` + i} value={oncall.mserp_mbapplicationid} selected>
                                            {oncall.mserp_mbapplicationid} - {oncall.mserp_notes}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <Skeleton variant="text" width="50%" />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
            <br></br>
            <Typography variant="h4">
                {t('zdeheader.timeBooking') +
                    ' ' +
                    t('zdeheader.hoursdistribute', {
                        from: remainingTimeDisplay.toFixed(2),
                        to: workingTimeDisplay.toFixed(2),
                    })}
            </Typography>
            <Divider sx={{ marginTop: '8px' }} />
            <ZDELines />
            <Box pt={5}>
                <ViolationComment />
            </Box>
        </>
    )
}

export default ZDEHeader
