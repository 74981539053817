import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import JournalLine from './JournalLine'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedJournal } from '../../source/slice/JournalSlice'
import { IJournal, IJournalLine } from '../../interface/IJournal'
import { IUser } from '../../interface/IUser'

const JournalLines = (props: any) => {
    const { t } = useTranslation()
    const journal :IJournal = useSelector((state: any) => state.journal?.value)
    const user: IUser = useSelector((state:any) => state.user?.value)

    const dispatch = useDispatch()
    
    const addNewLine = () => {
        var newEmptyLine: IJournalLine = {
            mserp_dataareaid: user.resource.mserp_resourcecompanyid,
            mserp_activitynumber: '',
            mserp_category: '',
            mserp_linenumber: 0,
            mserp_journalid: journal.header?.mserp_journalbatchnumber,
            mserp_projectdate: journal.header?.date,
            mserp_hours: 0,
            mserp_mbpurchlinerefrecid: 0,
            mserp_projectid: '',
            mserp_projjournaltransentityid: '00000000-0000-0000-0000-000000000000',
            mserp_resourceid: user.resource.mserp_resourceid,
            date: journal.header?.date,
            delete: false,
        }

        dispatch(
            setSelectedJournal({
                ...journal,
                lines: [...(journal.lines || []), newEmptyLine],
            })
        )
    }
    
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700, textOverflow: 'ellipsis', tableLayout: 'fixed' }}>
                    {/* <caption style={{ "textAlign": "end", color: "red"}}>2h von 8h noch zu verteilen</caption> */}
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: { xs: 84, md: 100, lg: 84, xl: 100 }, textAlign: 'center', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                                <Tooltip title={t('zdelines.add')}>
                                    <IconButton size="small" onClick={addNewLine}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.project')}</TableCell>
                            <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.activity')}</TableCell>
                            {user?.worker?.mserp_employmentcategory === 'FAK' ? <TableCell sx={{ width: '100%', padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>{t('zdelines.order')}</TableCell> : <></> }
                            <TableCell sx={{ width: { xs: 144, md: 152, lg: 144, xl: 152 }, padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}>{t('zdelines.amount')}</TableCell>
                            {/* <TableCell>{t('zdelines.comment')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {journal.lines?.map((row:any, i:number) => (
                            <JournalLine row={row} key={i} id={i} />
                        ))}

                        {/* ?.filter((row: TimecardLine) => row.mserp_indirectactivitycategoryid === '') */}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default JournalLines
