import React, { useEffect } from 'react'
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import './App.css'
import Navigation from './components/Navigation'
import Dashboard from './components/Dashboard'
import { DarkTheme, LightTheme } from '@mercedes-benz/mui5-theme'
import Footer from './components/Footer'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { setAlert } from './source/slice/AlertSlice'
import { useDispatch, useSelector } from 'react-redux'
import CustomSnackbar from './components/CustomSnackbar'
import LanguageDetector from 'i18next-browser-languagedetector'
import { setSelectedDate } from './source/slice/SelectedDateSlice'
import axios, { AxiosRequestConfig } from 'axios'
import { ITimecard } from './interface/ISelectedDate'
import { IUser } from './interface/IUser'
import { setUser } from './source/slice/UserSlice'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './authConfig'
import NotAuthenticated from './components/NotAuthenticated'
import { IProjects } from './interface/IProjects'
import { setProjects } from './source/slice/ProjectsSlice'
import { format } from 'date-fns'
import { setTheme } from './source/slice/ThemeSlice'
import { InteractionStatus } from '@azure/msal-browser'
import { initializeApplicationInsights } from './config/applicationinsights'
import { setOrders } from './source/slice/OrderSlice'
import { IOrdersRef } from './interface/IOrder'
import { setSelectedJournal } from './source/slice/JournalSlice'
import { IJournal } from './interface/IJournal'

const initializei18next = () => {
    i18next
        .use(initReactI18next)
        .use(Backend)
        .use(LanguageDetector)
        .init({
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
            //lng: 'en', // if you're using a language detector, do not define the lng option
            fallbackLng: 'en',

            interpolation: {
                escapeValue: false,
            },
        })
}

const App = () => {
    const { instance, accounts, inProgress } = useMsal()
    const isAuthenticated = accounts.length > 0
    const dispatch = useDispatch()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const user: IUser = useSelector((state: any) => state.user?.value)

    useEffect(() => {
        const initializeMsal = async () => {
            if (inProgress === InteractionStatus.None) {
                await instance.initialize()
                await instance.loginRedirect(loginRequest).catch((err: Error) => console.error('login failed', err))
            }
        }

        if (!isAuthenticated) {
            initializeMsal()
            initializei18next()
        } else {
            initializeApplicationInsights()
            getCustomSnackbar()
            //setDefaultSelectedDate()
            getUser()
            getProjects()
        }

        if (!localStorage.getItem('theme')) {
            dispatch(setTheme(prefersDarkMode ? 'dark' : 'light'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, instance])

    useEffect(() => {
        if (user.worker) {
            if (user?.worker?.mserp_usetimecard) {
                getSelectedDate(new Date().toISOString())
            } else {
                getSelectedJournal(new Date().toISOString())
            }
            if (user?.worker?.mserp_employmentcategory === 'ANÜ' || user?.worker?.mserp_employmentcategory === 'FAK') {
                getOrders()
            }
        }
    }, [user])

    const accessTokenRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
    }

    const getCustomSnackbar = () => {
        dispatch(setAlert(null))
    }

    const getSelectedDate = async (date: string) => {
        let newSelectedDate: ITimecard = {
            header: undefined,
            lines: [],
        }
        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        date: format(new Date(), 'yyyy-MM-dd'),
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, config)
                    .then((response) => {
                        newSelectedDate = response.data
                        dispatch(setSelectedDate(newSelectedDate))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSelectedJournal = async (date: string) => {
        let newSelectedDate: IJournal = {
            header: undefined,
            lines: [],
        }
        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        date: format(new Date(), 'yyyy-MM-dd'),
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/Journal`, config)
                    .then((response) => {
                        newSelectedDate = response.data
                        dispatch(setSelectedJournal(newSelectedDate))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getProjects = async () => {
        let newProjects: IProjects[]

        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                        date: format(new Date(), 'yyyy-MM-dd'),
                    },
                }
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/Projects`, config)
                    .then((response) => {
                        newProjects = response.data
                        dispatch(setProjects(newProjects))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getOrders = async () => {
        let newOrders: IOrdersRef[]

        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                        date: format(new Date(), 'yyyy-MM-dd'),
                    },
                }
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/Orders`, config)
                    .then((response) => {
                        newOrders = response.data
                        dispatch(setOrders(newOrders))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getUser = async () => {
        await instance.initialize()
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    data: {},
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }
                axios
                    .get(`${process.env.REACT_APP_BACKEND_URL}/User`, config)
                    .then((response) => {
                        var newUser: IUser = {
                            userid: accounts[0]?.username,
                            token: accounts[0]?.idToken,
                            worker: response.data?.worker,
                            resource: response.data?.resource,
                        }
                        dispatch(setUser(newUser))
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //const theme = React.useMemo(() => (prefersDarkMode ? DarkTheme : LightTheme), [prefersDarkMode])
    const theme = useSelector((state: any) => state.theme?.value)

    return isAuthenticated ? (
        <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>
            <CssBaseline />
            <Navigation />
            <CustomSnackbar />
            <Dashboard />

            <Footer />
        </ThemeProvider>
    ) : (
        <NotAuthenticated />
    )
}

export default App
